import React from 'react'
import './UIUXSection.scss';
import UIUX from './uiux.jpg';

function UIUXSection() {
    return (
        <div className="Section-layout">
            <div className="Section-container">
                <div className="Section-image-container">
                    <img
                        className="Section-image"
                        src={UIUX}
                        alt="UI / UX"
                    />
                </div>
                <div className="Section-content">
                    <h1>UI / UX Design</h1>
                    <p className="moremarginBottom">I have done a number of works in terms of User Experience Design, User Research, Survey and User Interface Design in different fields.         
                      Turning user frustrations into seamless interactions</p>                    
                    <div className="button_link">
                        <a rel="noopener noreferrer" target='_blank' href="https://www.behance.net/jaylinxr"><span>Explore More</span></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UIUXSection
